/* This mixin is for generating CSS arrows on a box */
@mixin box-arrow($arrowDirection, $arrowColor, $arrowSize: 12px) {
  position: relative;
  z-index: 10;

  &::after {
    content: '';
    width: 100%;
    height: 0;
    display: block;
    position: absolute;
    z-index: 10;
    border: 0;
    left: 0;

    @if $arrowDirection == bottom or $arrowDirection == top {
      // border-left: $arrowSize solid transparent;
      // border-right: $arrowSize solid transparent;
      // margin-left: -$arrowSize;
      // left: 50%;

      @if $arrowDirection == bottom {
        border-top: $arrowSize solid $arrowColor;
        bottom: -$arrowSize;
      }

      @if $arrowDirection == top {
        border-bottom: $arrowSize solid $arrowColor;
        top: -$arrowSize;
      }
    }

    @if $arrowDirection == left or $arrowDirection == right {
      // border-top: $arrowSize solid transparent;
      // border-bottom: $arrowSize solid transparent;
      // margin-top: -$arrowSize;
      // top: 50%;

      @if $arrowDirection == left {
        border-right: $arrowSize solid $arrowColor;
        left: -$arrowSize;
      }

      @if $arrowDirection == right {
        border-left: $arrowSize solid $arrowColor;
        left: auto;
        right: -$arrowSize;
      }
    }
  }
}

// set some variables to reuse

$boxbkg: #fff;

.c-box {
  background: $boxbkg;
  text-align: center;
  color: #fff;
  padding: 5vmin;
  margin: 5vmin;
  
  &--arrow-top {
    @include box-arrow(top, $boxbkg);  
  }
  
  &--arrow-right {
    @include box-arrow(right, $boxbkg);  
  }
  
  &--arrow-bottom {
    @include box-arrow(bottom, $boxbkg);  
  }
  
}

/* Style the buttons that are used to open and close the accordion panel */
.project {
  background-color: #faf9f9;
  color: #838383;
  cursor: pointer;
  // padding: 0px 12px;
  // display: flex;
  align-items: center;
  border: none;
  outline: none;
  text-decoration: " none";
  /* Set up the hover */
  /* If you aren't using autoprefix, remember to prefix the gradient for other browsers */
  background-size: 0 3px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
  border-radius: 1px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

.project:hover {
  background-image: linear-gradient(#b1b1b1, #b1b1b1);
  background-size: 90% 2px, auto;
  font-weight: bold;
  color: #2f3e6c;
  // background-color: #f4f4f4;
}

// .accordion:hover,
.project-active {
  // background-color: #fff;
  cursor: pointer;
  padding: 0px 12px;
  // display: flex;
  align-items: center;
  border: none;
  outline: none;
  text-decoration: " none";
  /* Set up the hover */
  /* If you aren't using autoprefix, remember to prefix the gradient for other browsers */
  // background-size: 90% 2px, auto;
  // border-bottom: 2px solid #edebeb;
  background-image: linear-gradient(#2f3e6c, #2f3e6c);
  background-size: 90% 2px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
  border-radius: 1px;
  color: #2f3e6c;
}

.project-active:hover {
  background-image: linear-gradient(#005eff, #005eff);
  background-size: 90% 2px, auto;
}
