@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  margin: 12px 0 12px 22px;
  flex-grow: 1;
}
.formData__section{
  display: flex;
  flex-direction: column;
  // margin: 12px 0 12px 1px;
  flex-grow: 1;
  height: 45px;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #faf9f9;
  color: #838383;
  cursor: pointer;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  text-decoration: " none";
  /* Set up the hover */
  /* If you aren't using autoprefix, remember to prefix the gradient for other browsers */
  background-size: 0 3px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
  border-radius: 1px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.dropDown {
  background-color: #faf9f9;
  color: #838383;
  cursor: pointer;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  text-decoration: " none";
  background-size: 0 3px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
  border-radius: 1px;
  border: 1px solid #00000073;
}

.accordion:hover {
  background-image: linear-gradient(#b1b1b1, #b1b1b1);
  background-size: 90% 2px, auto;
  color: #2f3e6c;
  background-color: #f4f4f4;
}

// .accordion:hover,
.active {
  // background-color: #fff;
  cursor: pointer;
  padding: 0px 12px;
  display: flex;
  // align-items: center;
  border: none;
  outline: none;
  text-decoration: " none";
  /* Set up the hover */
  /* If you aren't using autoprefix, remember to prefix the gradient for other browsers */
  // background-size: 90% 2px, auto;
  // border-bottom: 2px solid #edebeb;
  background-image: linear-gradient(#edebeb, #edebeb);
  background-size: 0 3px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
  border-radius: 1px;
  color: #2f3e6c;
  font-weight: bold;
  
}

.active:hover {
  background-image: linear-gradient(#005eff, #005eff);
  background-size: 90% 2px, auto;
}

/* Style the accordion content title */
.accordion__div{
  display: flex; 
  flex-direction:row;
  height:100%;
  align-items:center
}

.accordion__title {
  font-family: "Source Sans Pro", sans-serif;
  // font-weight: 600;
  font-size: 13px;
  text-align: left;
  // display: block;
  display: flex;
  // margin-block-start: 1em;
  // margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  min-height: 37px;
  width: 196px;
}

.form_title {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  text-align: left;
  display: flex;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  width: 196px;
}

.snags_accordion__title {
  font-family: "Source Sans Pro", sans-serif;
  // font-weight: 600;
  font-size: 13px;
  text-align: left;
  // display: block;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  // margin-block-start: 1em;
  // margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  min-height: 58px;
  width: 196px;
}

.TextFont{
  display:flex;
  align-items: center;
  margin-left:4px;
  color: #2f3e6c;
}
/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(180deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: #faf9f9;
  // overflow: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}

.flexBox{
  display: flex;
  flex-direction: column;
  align-items: normal;
}

.from_date{
  height: 14px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: #b5b5b5;
  margin-bottom: 12px;
}
.from_date:hover span {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 11px;
  font-weight: normal;
  color: #b5b5b5;
}
.selected_state {
  font-weight:"bold";
  color: #2f3e6c;
}

.accordion:hover {
  color: #2f3e6c ;
  font-weight: bold;
}
