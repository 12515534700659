.DateRangePicker__CalendarSelection {
    background-color: #005effbf;
    border: 1px solid #005effbf;
}
// .DateRangePicker__FullDateStates {
//     bottom: 0;
//     left: 0;
//     position: absolute;
//     right: 0;
//     top: 0;
//     background-color: rgb(209, 209, 209);
// }