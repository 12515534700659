.loading {
  background: linear-gradient(90deg, #e9e9e9, #e9e9e9 15%, #d9d9d9 25%, #9f9f9f 35%, #9f9f9f 45%, #d9d9d9 65%, #e9e9e9 75%);
  background-size: 400% 400%;
  animation: gradientBG 3s ease-in infinite reverse;
}

@-webkit-keyframes gradientBG {
  0% {
    background-position: -10% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
@keyframes gradientBG {
  0% {
    background-position: -10% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
