$animate: all 0.3s ease-in-out;

/** Header and other non-related styling **/



/** Hover-related CSS **/
.ulClass { 
    background-color: #f4f4f4;
  padding: 0px;
}
 
.liClass {
  margin-left: 11px;
  list-style: none;
  letter-spacing: normal;
  font-size: 16px;
  line-height: 24px;
  color: #444;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  transition: text .8s ease;
  color: #005eff;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 85px;
}

.ulClass:hover > .liClass {
    // text-align: left;
  // width: 0%;
  // background-image: url(../../assets/images/Arrow.svg) "no-repeat";
}

.examples {
  display: flex;
  flex-flow: column;
  max-width: 100%;
  width: 120px;
  // height: 30px; 
  background-color: #fafafa;
  opacity: 0.95;
  .example {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-width: 123px;
    .hover {
  height: 50px;
      display: flex;
    align-items: center;
      font-size: 0px;
      text-align: center;
      margin: 0 auto;
      transition: $animate;
      position: relative;
      width:100%;
      background-color: #f4f4f4;
      &:before,
      &:after {
        content: "";
        position: absolute;
        bottom: 0px;
        width: 0px;
        height: 2px;
        margin: 5px 0 0;
        transition: $animate;
        transition-duration: 0.3s;
        opacity: 0;
        background-color: #f4f4f4;
      }
      &.hover-3 {
        &:before {
          left: 50%;
        }
        &:after {
          right: 50%;
        }
      }
    }
    &:hover {
      cursor: pointer;
      .hover {
        &:before,
        &:after {
          width: 100%;
          opacity: 1;
     
        }
        &.hover-3{
          &:before,
          &:after {
            width: 50%;
          }
        }
      }
    }
  }
}

.arrow {
  position: absolute;
  top: 10px;
  right: 16px;
  // visibility: hidden;
}

.examples:hover .arrow, .arrow:hover{
  visibility: visible;
}

.fslightbox-toolbar-button:nth-child(1) {
  display: none;
  }